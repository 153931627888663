export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "sprunki parasite",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://wowtbc.net/sprunki/parasites/index.html",
    domain: "sprunkiparasite.org",
    gaId: "G-NGQ7889B4T",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
